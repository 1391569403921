import { useRef } from 'react';
import {
    AppBar, Toolbar,
} from '@mui/material';
import { useSelector, useDispatch } from 'store';
import { set } from 'store/reducers/modal';
import { turnOn } from 'store/reducers/audio';
import Logo from 'components/logo';

import BackgroundAudio from 'components/audio/background';
import ButtonClickAudio, { ButtonClickAudioRef } from 'components/audio/button-click';

function MobileHeader() {
    const dispatch = useDispatch();
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const { volume } = useSelector((state) => state.audio);

    const buttonClickAudioRef = useRef<ButtonClickAudioRef>(null);

    const openAuthModal = (value: string) => {
        playButtonClickAudio();
        dispatch(set(value));
    };

    const handleChangeVolumn = () => {
        dispatch(turnOn(volume > 0 ? 0 : 1));
    };

    const playButtonClickAudio = () => {
        if (buttonClickAudioRef.current) {
            buttonClickAudioRef.current.playAudio();
        }
    };

    return (
        <>
            <AppBar position="fixed" sx={{ background: "none", boxShadow: "none" }}>
                <Toolbar disableGutters sx={{ justifyContent: { xs: "center", sm: "space-between" }, alignItems: "flex-start" }}>

                    <Logo
                        sx={{ height: "100%", width: "100%", marginTop: "0px", justifyContent: "center" }}
                    />

                </Toolbar>
            </AppBar>

            {/* <BackgroundAudio />
            <ButtonClickAudio ref={buttonClickAudioRef} /> */}
        </>
    );
};

export default MobileHeader;
