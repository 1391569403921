import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Box, Stack } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch, useSelector } from 'store';
import { remove } from 'store/reducers/modal';

import LoginView from 'sections/mobile/auth/login';
import PasswordChangeView from 'sections/mobile/auth/password-change';
import RuleView from 'sections/mobile/auth/rules';

import CloseButton from 'assets/images/mobile/close-button.png';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogComponent = styled(Dialog)(({ theme }) => ({
    bottom: "66px",
    zIndex: 13,
    '& .MuiModal-backdrop': {
        // backdropFilter: "blur(8px)"
    },
    '& .MuiDialog-container': {
        alignItems: "end",
        '& .MuiDialog-paper': {
            display: "flex",
            flexDirection: "column",
            background: "linear-gradient(180deg,#090d28,rgba(71,86,122,.79) 14.6px,rgba(50,60,85,.76) 22px,rgba(47,57,81,.9) 33px,rgba(15,20,32,.72) 47.5px,rgba(0,0,0,.62) 55px,rgba(14,18,27,.61)),linear-gradient(180deg,rgba(39,47,66,.83),#262f45)",
            padding: "16px 11px 0",
            margin: "0px 10px",
            maxHeight: "480px",
            width: "92.5vw",
            borderRadius: "23px 23px 0 0",
            border: "2px solid #4d587f",
            borderBottom: "none",
            '&:before': {
                content: '" "',
                position: "absolute",
                top: 0,
                transform: "translateX(-50%)",
                left: "50%",
                width: "34.4%",
                height: "4px",
                borderButtomRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                backgroundColor: "#cddeff",
                zIndex: 1
            },
            '& .MuiDialogTitle-root': {
                padding: 0,
                marginBottom: "25px",
                paddingBottom: "10px",
                borderBottom: "1px solid hsla(0,0%,100%,.17)",
                fontWeight: 700,
                fontSize: "16px",
                textTransform: "uppercase",
                textAlign: "center",
                color: "#cddeff"
            }
        }
    },
}));

const AuthDialogView = () => {
    const dispatch = useDispatch();
    const { name } = useSelector(state => state.modal);

    const closeModal = () => {
        dispatch(remove());
    };

    return (
        <DialogComponent
            open={name !== ""}
            TransitionComponent={Transition}
            maxWidth="xs"
            fullWidth={true}
            onClose={closeModal}
        >
            {
                name !== "rule" && <Stack direction="row" justifyContent="end">
                    <IconButton
                        onClick={closeModal}
                        sx={{ height: "20px", padding: 0 }}
                    >
                        <Box component="img" src={CloseButton} height={1} sx={{ '&:hover': { filter: "blur(1px)" } }} />
                    </IconButton>
                </Stack>
            }

            <DialogTitle>
                {name === "login" ? "Log in to your account" : name === "register" ? "Account Registration" : name === "rule" ? "Sweepstakes Rules" : "Password Change"}
            </DialogTitle>

            <DialogContent sx={{ ...(name === "rule" && { padding: 0 }) }}>
                {name === "login" && <LoginView />}
                {name === "password-change" && <PasswordChangeView closeModal={closeModal} />}
                {name === "rule" && <RuleView closeModal={closeModal} />}
            </DialogContent>
        </DialogComponent>
    );
};

export default AuthDialogView;