import { ReactNode } from 'react';
import { Stack, Box } from '@mui/material';
import { setFullScreen } from 'utils/set-screen';
import { useResponsive } from 'hooks/use-responsive';

// import DesktopHeader from 'layouts/desktop-header';
import MobileHeader from 'layouts/mobile-header';

import MobileAuthDialogView from 'sections/mobile/auth';

import MainBackground from 'assets/images/background.webp';

const Layout = ({ children }: { children: ReactNode }) => {
  const { isMobile } = useResponsive();

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        height: { xs: "100%", md: "100vh" },
        width: "100vw",
        overflow: "hidden",
        touchAction: "pan-x",
        // ...(isMobile ?
        //   { backgroundColor: "#030022", display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "35px 1fr 172px" }
        //   :
        //   {
        backgroundImage: `url(${MainBackground})`,
        backgroundSize: "100% 100%",
        '&:before': {
          content: '" "',
          position: "absolute",
          backgroundColor: "rgba(0, 0, 0, .8)",
          width: 1,
          height: 1
        }
        //   }
        // )
      }}
      onClick={setFullScreen}
    >
      {/* {
        isMobile ?
          <>
            <MobileHeader />

            {children}

            <MobileAuthDialogView />
          </>
          : */}
      <Box
        height={1}
        width={1}
        maxWidth={550}
        sx={{
          mx: "auto",
          backgroundColor: "#030022",
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "35px 1fr 172px"
        }}
      >
        <MobileHeader />

        {children}

        <MobileAuthDialogView />
      </Box>
      {/* } */}
    </Stack>
  );
};

export default Layout;
