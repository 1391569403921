import { Button, Box, Typography, Stack } from '@mui/material';

const RuleView = ({ closeModal }: { closeModal: () => void }) => {

    return (
        <>
            <Box
                sx={{ display: "flex", flexDirection: "column", gap: 0.5, backgroundSize: "100% 100%", minHeight: "300px", color: "white" }}
            >
                <Typography variant="body1" fontWeight={600} textTransform="uppercase">Software Disclaimer</Typography>

                <Typography variant="caption">Thank you for playing. This software is designed solely for the amusement and entertainment of the players.</Typography>
                <Typography variant="caption">By continuing to play, You acknowledge and agree to the following:</Typography>

                <Stack direction="row" gap={0.5}>
                    <Typography variant="caption">{"(1)"}</Typography>
                    <Typography variant="caption">You are 18years of age:</Typography>
                </Stack>

                <Stack direction="row" gap={0.5}>
                    <Typography variant="caption">{"(2)"}</Typography>
                    <Typography variant="caption">You agree that neither the Distributor or Manufacturer of the Software have any liability related to the Software including the redemption of any voucher related to game play:</Typography>
                </Stack>

                <Stack direction="row" gap={0.5}>
                    <Typography variant="caption">{"(3)"}</Typography>
                    <Typography variant="caption">You agree that neither the Distributor or the Manufacturer will be liable to you for any loss or damage incurred by you, whether direct, indirect, incidental, special, exemplary or consequential:</Typography>
                </Stack>

                <Stack direction="row" gap={0.5}>
                    <Typography variant="caption">{"(4)"}</Typography>
                    <Typography variant="caption">The Distributor, Manufacturer and Retail Operator make no Representations or Warranties, express or implied regarding the Software or game play. Please Play Responsibly.</Typography>
                </Stack>
            </Box>

            <Stack direction="row" justifyContent="end">
                <Button
                    sx={{
                        color: "#96f2e7",
                        fontWeight: 600,
                        cursor: "pointer"
                    }}
                    onClick={closeModal}
                >I Agree</Button>
            </Stack>
        </>
    );
};

export default RuleView;